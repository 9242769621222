.b-nav_aux {
	@include g-section_holder;

	color: $color-text-dimmed;
	margin-bottom: rh(8);
	margin-top: rh(8);

	@include media(sm) {
		margin-bottom: rh(6);
		margin-top: rh(6);
	}
}
