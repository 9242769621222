.b-ship_to {
	@include media(md-down) {
		align-items: center;
		display: flex;
		justify-content: space-between;

		@include icon(arrow-right-thin, $icon-width: 7px, $icon-height: 13px, $position: after) {
			margin-left: rh(1);
		}
	}

	&-link {
		align-items: center;
		cursor: pointer;
		display: flex;

		@include media(lg-up) {
			padding: rh(3); // to increase clickable area

			@include icon(arrow-down, $icon-width: 4px, $icon-height: 7px, $position: after) {
				margin-left: rh(1);
			}
		}

		@include media(md-down) {
			align-items: center;
			display: flex;
			font: $configuration-category_navigation-link-font;
			letter-spacing: $configuration-category_navigation-link-letter_spacing;
			text-transform: $configuration-category_navigation-link-text_tranform;
			@include icon(box, $icon-width: 14px, $icon-height: 15px) {
				margin-right: rh(2);
			}
		}

		&:hover {
			text-decoration: none;
		}
	}

	&-state {
		border-bottom: 1px dashed currentColor;
		margin-left: rh(1);
	}
}
