.b-header_promo {
	background-color: $color-header_promo-background;
	max-height: 0;
	opacity: 0;
	position: relative;
	visibility: hidden;

	&.m-visible {
		max-height: 80px;
		opacity: 1;
		padding: rh(2 6);
		visibility: visible;
	}

	&-content {
		@include lines-clamp;

		font-size: 14px;
		line-height: 20px;
		text-align: center;
	}

	&-close {
		@include icon(close, $icon-width: 10px, $icon-height: 10px);

		cursor: pointer;
		font-size: 0;
		padding: rh(2);
		position: absolute;
		right: 5px;
		top: 5px;
	}
}
