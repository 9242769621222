// See specs https://www.w3.org/TR/WCAG20-TECHS/G1.html
.b-skip_to {
	background-color: $color-skip_to-background;
	box-shadow: $depth-1;
	clip: rect(1px, 1px, 1px, 1px);
	color: $color-skip_to-color;
	display: block;
	left: 0;
	margin: 0 auto;
	max-width: 320px;
	opacity: 0;
	overflow: hidden;
	padding: rh(3 12);
	position: absolute;
	right: 0;
	text-align: center;
	text-decoration: none;
	top: rh(2);
	transition: $motion-ease;
	transition-property: opacity, clip;
	z-index: z(skip-to);

	&:focus {
		clip: auto;
		opacity: 1;
	}
}
