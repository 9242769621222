body {
	opacity: 0;
	overflow: hidden;
	pointer-events: none;
	visibility: hidden;
	transition: opacity 0.3s;
}

// show header only
// TODO:vu: check classes
.b-header,
.b-menu_panel {
	pointer-events: all;
	visibility: visible;
}

.b-header-account,
.b-header-navigation_bottom,
.b-header-navigation_link {
	display: none;
}

// Critical part of dialog component. Needed to be loaded synchronously.
.b-dialog {
	visibility: hidden;
}

//================
.b-shipping_panel-inner,
.b-header_login-panel_inner {
	opacity: 0;
	visibility: hidden;
}
