/*md

# Breadcrumbs

```html example
<nav class="b-breadcrumbs">
	<ul class="b-breadcrumbs-list">
		<li class="b-breadcrumbs-item ">
			<a class="b-breadcrumbs-link" href="#">Home</a>
		</li>
		<li class="b-breadcrumbs-item ">
			<a class="b-breadcrumbs-link" href="#">Women</a>
		</li>
		<li class="b-breadcrumbs-item m-current">
			<span class="b-breadcrumbs-link m-current">Accessories</span>
		</li>
	</ul>
</nav>
```

*/

.b-breadcrumbs {
	@include scrollbar(hidden);

	overflow-x: auto;
	scrollbar-width: none;

	&-list {
		display: flex;
	}

	&-item {
		display: flex;
		line-height: 1.2;
		white-space: nowrap;

		&:first-child {
			display: none;
		}

		&:not(:nth-last-child(2)) {
			@include media(sm) {
				display: flex;
				margin: 0;
				opacity: 0;
				width: 0;
				z-index: -1;
			}
		}

		&:not(.m-current) {
			@include media(md-up) {
				@include icon('separator', 1px, 12px, after) {
					background: $color-breadcrumb-separator_bg;
					margin: 2px 15px 0;
				}
			}
		}
	}

	&-link {
		@include t-link_1(
			$color: $color-breadcrumb-link_color,
			$color-visited: currentColor
		);

		@include media(sm) {
			--breadcrumb-icon-offset: #{rh(4)};

			background: none;
			margin-left: var(--breadcrumb-icon-offset);
			padding-bottom: 0;
			position: relative;

			@include icon('arrow-left') {
				left: calc(-1 * var(--breadcrumb-icon-offset));
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&.m-current {
			background: none;
			color: var(--color-cur-breadcrumb, $color-breadcrumb-current);
			cursor: default;
			font-weight: normal;

			&::after {
				display: none;
			}
		}
	}
}
