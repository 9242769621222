.b-header_login {
	position: relative;

	&-title_link {
		align-items: center;
		cursor: pointer;
		display: flex;
		padding: rh(3); // to increase clickable area

		@include media(md-down) {
			display: inline-block;
			padding: rh(2); // to increase clickable area
		}

		@include icon(account, $icon-width: 13px, $icon-height: 16px) {
			@include media(lg-up) {
				margin-right: rh(2);
			}
		}

		&:hover {
			text-decoration: none;
		}
	}

	&-panel {
		@include g-panel($el: overlay);

		&.m-opened {
			@include g-panel($el: overlay, $mod: opened);
		}
	}

	&-panel_inner {
		@include g-panel($el: panel);
		@include g-panel($el: panel, $mod: right);

		background: var(--account_panel-background, $color-account_panel-background);
		padding: rh(9 10);

		&.m-image_background {
			background: var(--account_panel-background_image) center center / cover;
		}
	}

	&-panel.m-opened &-panel_inner {
		@include g-panel($el: panel, $mod: opened);
		@include g-panel($el: panel, $mod: right_opened);
	}

	.b-button_close {
		position: absolute;
		right: 5px;
		top: 5px;
	}
}
