.l-page {//TODO:vu: check modification classes - move to base
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	&-content {
		flex-grow: 1;
	}

	&-footer {
		margin-top: auto;
	}

	&.m-has_dialog {
		height: auto;
		overflow: hidden;
		position: fixed;
		width: 100%;
	}

	&.m-has_dialog &-content,
	&.m-has_dialog &-footer { // we need a wrapper to handle scroll / no scroll content width bumping
		overflow-y: scroll;
	}

	&.m-error_page {
		overflow: auto;
	}
}
