
/*md
# Carousel

## Core customization options

`b-carousel` uses `g-carousel` component and so all core customizations
options are available (which can be found
[here](./02-components-carousel-g-carousel.html))

## Carousel variations

There are predefined modifications for `b-carousel` which will be described
in details later. Here's a brief list:

### Preset modifications (only one at a time)
 * m-preset-products - used for "Recently viewed products" slider
 * m-preset-recommendation - used for "Products recommendations" slider

### Layout modifications (only one at a time)
 * m-layout-controls_above - Controls and title above carousel
 * m-layout-controls_left_side - Controls and title on the left carousel on the right side
 * m-layout-controls_top_left - Controls on the top left side (without title and subtitle block)
 * m-layout-controls_vertically_centered - Controls on the sides, vertically centered (without title and subtitle block)
 * m-layout-pagination_and_controls - Controls vertically centered and Pagination over the track with slides (without title and subtitle block)

### Theme modifications
 * m-inverse - Inverse control colors

## Preset - Products

### Description

This preset is used for "Recently viewed products" slider - together with
`m-layout-controls_above` layout modification.

### Details

- XL: Slide width equals 297px;
- LG: 4.5 slides per view;
- MD: Slide width equals 246px;
- SM: 1.3 slides per view.

```html_example
<style>
.b-carousel img { width: 100%; }
</style>
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_above m-preset-products
	"
	data-widget="carousel"
>
	<div class="b-carousel-title_container">
		<h2 class="b-carousel-title">Carousel Title</h2>
		<div class="b-carousel-sub_title">Subtitle</div>
	</div>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Preset - Recommendations

### Description

This preset is used for "Product recommendations" slider - together with
`m-layout-controls_left_side` layout modification.

### Details

- XL: Slide width equals 297px;
- LG: 3 slides per view. First slide width is 1.5 of a regular slide
	and 2 rows;
- MD: Slide width equals 246px;
- SM: 1.3 slides per view.

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_left_side m-preset-recommendation
	"
	data-widget="carousel"
>
	<div class="b-carousel-title_container">
		<h2 class="b-carousel-title">Carousel Title</h2>
		<div class="b-carousel-sub_title">Subtitle</div>
	</div>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Modification - Layout - Controls above

### Description

This modification defines layout for carousel
elements, title and subtitle:
- title and subtitle at the left side, above the carousel slides
- previous and next buttons at the right side, above the carousel slides

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_above
	"
	data-widget="carousel"
>
	<div class="b-carousel-title_container">
		<h2 class="b-carousel-title">Carousel Title</h2>
		<div class="b-carousel-sub_title">Subtitle</div>
	</div>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Modification - Layout - Controls left side

### Description

This layout modification positions prev/next controls,
title, subtitle, carousel slides:
- title and subtitle: top left side;
- prev/next controls: below title and subtitle;
- carousel slides: at the right side.

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_left_side
	"
	data-widget="carousel"
>
	<div class="b-carousel-title_container">
		<h2 class="b-carousel-title">Carousel Title</h2>
		<div class="b-carousel-sub_title">Subtitle</div>
	</div>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Modification - Layout - Controls top left

### Description

Positions prev/next controls above the carousel slides
at the left side.

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_top_left
	"
	data-widget="carousel"
>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Modification - Layout - Controls vertically centered

### Description

Positions prev/next controls in front of carousel slides:

- previous button at the left side vertically at the middle;
- next button at the right side and vertically at the middle.

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_vertically_centered
	"
	data-widget="carousel"
>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Modification - Layout - Controls vertically centered and Pagination Over slider

### Description

Positions prev/next controls in front of carousel slides:

- previous button at the left side vertically at the middle;
- next button at the right side and vertically at the middle.

Positions pagination conrols in front of the carousel slides
at the bottom and horizontally center aligned.

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-pagination_and_controls
	"
	data-widget="carousel"
>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Modification - Theme - Inverse

### Description

This modification inverts prev/next controls colors
so it better works with dark slides.

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_vertically_centered
		m-inverse
	"
	data-widget="carousel"
>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200/000/ddd" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200/000/ddd" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200/000/ddd" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```
*/

.b-carousel {
	@include g-carousel(root);

	--product_tile-width: 100%;
	--carousel-gutter: #{rh(3)};

	display: grid;
	grid-template:
		'title'
		'controls'
		'carousel'
		'pagination'
		/
		1fr;

	&-title_container {
		grid-area: title;
		margin-bottom: rh(6);
	}

	&-title {
		@include t-heading_2;
	}

	&-sub_title {
		@include t-paragraph_2;

		margin-top: rh(4);
	}

	&-track {
		@include g-carousel(track);
		@include g-carousel(track, mandatory);

		grid-area: carousel;
	}

	&.m-no-scroll &-track {
		@include g-carousel(track, no_scroll);
	}

	&-slide {
		@include g-carousel(slide);
		@include g-carousel(slide, snap-to-start);
	}

	&.m-page_designer &-slide {
		justify-items: stretch;
	}

	&-controls {
		@include g-carousel(controls_container);

		grid-area: controls;
	}

	&:not(.m-prev_visible):not(.m-next_visible) &-controls {
		display: none;
	}

	&-control {
		@include g-carousel(control);

		--icon-color: var(--carousel-control-color, #{$color-carousel_control});

		align-items: center;
		border: 2px solid var(--carousel-control-color, #{$color-carousel_control});
		border-radius: 50%;
		height: var(--carousel-control-size, 48px);
		justify-content: center;
		text-align: center;
		transition: $motion-fast;
		transition-property: background-color, opacity;
		width: var(--carousel-control-size, 48px);

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				box-shadow: 0 3px 5px rgba($color-carousel_control, 0.25);
			}
		}
	}

	&.m-inited &-control {
		@include g-carousel(control, initialized);

		display: inline-flex;

		@include media(md-down) {
			display: none;
		}
	}

	&-control[disabled],
	&.m-no_scroll &-control {
		@include g-carousel(control, disabled);

		opacity: 0.3;
	}

	&-control.m-next {
		@include g-carousel(control, next);
		@include icon(arrow-right);
	}

	&-control.m-previous {
		@include g-carousel(control, previous);
		@include icon(arrow-left);

		margin-right: rh(2);
	}

	&.m-no-scroll &-control {
		@include g-carousel(control, no_scroll);
	}

	&-pagination_container {
		@include g-carousel(pagination_container);

		grid-area: pagination;
		margin-top: rh(6);
		text-align: center;
		width: 100%;
	}

	&.m-no-scroll &-pagination_container {
		@include g-carousel(pagination_container, no_scroll);
	}

	&-pagination_control {
		@include g-carousel(pagination_control);

		background-color: var(--carousel-pagination_control-color, #{$color-carousel_pagination_control});
		border-radius: 50%;
		height: rh(2);
		margin: 0 12px;
		width: rh(2);

		&.m-current {
			@include g-carousel(pagination_control, active);

			--carousel-pagination_control-color: #{$color-carousel_pagination_control__active};
		}
	}

	// PRESETS
	&.m-preset-products {
		@media screen and (min-width: 1440px) {
			--carousel-slides-width: 378.75px;
		}

		//iPAD Pro Landscape View and Desktop View
		@media screen and (min-width: 1200px) and (max-width: 1439px) {
			--carousel-number_of_gaps_per_view: 3;
			--carousel-slides_number: 4.36;
		}

		//iPAD Pro Portrait View
		@media screen and (min-width: 1024px) and (max-width: 1199px) {
			--carousel-number_of_gaps_per_view: 2;
			--carousel-slides_number: 3.047;
		}

		//Ipad View
		@media screen and (min-width: 768px) and (max-width: 1023px) {
			--carousel-slides-width: 236px;
		}

		@media screen and (max-width: 767px) {
			--carousel-number_of_gaps_per_view: 1;
			--carousel-slides_number: 1;
		}

		&.double-product {
			@media screen and (max-width: 767px) {
				--carousel-number_of_gaps_per_view: 1;
				--carousel-slides_number: 2.095;
			}
		}
	}

	&.m-preset-recommendation {
		@include media(xl) {
			--carousel-slides-width: 297px;
		}

		@include media(lg) {
			--carousel-number_of_gaps_per_view: 4;
			--carousel-slides_number: 4.5;
		}

		@include media(md) {
			--carousel-slides-width: 246px;
		}

		@include media(sm) {
			--carousel-number_of_gaps_per_view: 1;
			--carousel-slides_number: 1;
		}
	}

	&.m-preset-recommendation {
		@include media(lg) {
			--carousel-slides_number: 3;
			--carousel-number_of_gaps_per_view: 2;
		}
	}

	&.m-preset-recommendation &-slide {
		@include media(lg-up) {
			grid-row: 1 / 2;
		}
	}

	// &.m-preset-recommendation &-slide:first-child {
	// 	@include media(lg-up) {
	// 		grid-row: span 2;
	// 	}
	// }

	&.m-preset-recommendation &-track {
		@include media(lg-up) {
			grid-template-columns: var(--carousel-slides-width);
			grid-template-rows: auto 1fr;
		}
	}

	// LAYOUT VARIATIONS. Controls and title above carousel
	&.m-layout-controls_above {
		display: grid;
		grid-template:
			'title'
			'carousel'
			/
			1fr;

		@include media(lg-up) {
			grid-template:
				'title controls'
				'carousel carousel'
				/
				1fr #{rh(30)};
		}
	}

	&.m-layout-controls_above &-pagination_container {
		display: none;
	}

	// Controls and title on the left, carousel on the right side
	&.m-layout-controls_left_side {
		display: grid;
		grid-template:
			'title'
			'carousel'
			/
			1fr;

		@include media(lg-up) {
			grid-template:
				'title carousel' fit-content(rh(28))
				'controls carousel' max-content
				/
				1fr 2fr;
		}
	}

	&.m-layout-controls_left_side &-controls { // stylelint-disable-line no-descending-specificity
		align-self: auto;
	}

	&.m-layout-controls_left_side &-pagination_container {
		display: none;
	}

	// Controls on the top left side
	&.m-layout-controls_top_left {
		display: grid;
		grid-template:
			'controls'
			'carousel'
			/
			1fr;

		@include media(lg-up) {
			column-gap: rh(15);
			grid-template:
				'controls'
				'carousel'
				/
				1fr;
		}
	}

	&.m-layout-controls_top_left &-control {
		--carousel-control-size: 40px;
	}

	&.m-layout-controls_top_left &-title_container,
	&.m-layout-controls_top_left &-pagination_container {
		display: none;
	}

	// Controls on the sides, vertically centered
	&.m-layout-controls_vertically_centered &-control {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	&.m-layout-controls_vertically_centered &-control.m-next {
		right: var(--carousel-controls-offset, rh(7));
	}

	&.m-layout-controls_vertically_centered &-control.m-previous {
		left: var(--carousel-controls-offset, rh(7));
	}

	&.m-layout-controls_vertically_centered &-title_container {
		display: none;
	}

	&.m-layout-controls_vertically_centered &-pagination_container {
		@include media(lg-up) {
			display: none;
		}
	}
	/* stylelint-disable-next-line */
	&.m-layout-controls_vertically_centered &-controls {
		margin: 0;
	}

	// Controls on the sides, vertically centered
	// Pagination controls over slider
	&.m-layout-pagination_and_controls &-control {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	/* stylelint-disable-next-line */
	&.m-layout-pagination_and_controls &-controls {
		margin: 0;
	}

	&.m-layout-pagination_and_controls &-control.m-next {
		right: var(--carousel-controls-offset, rh(7));
	}

	&.m-layout-pagination_and_controls &-control.m-previous {
		left: var(--carousel-controls-offset, rh(7));
	}

	&.m-layout-pagination_and_controls &-pagination_container {
		bottom: rh(6);
		left: 0;
		position: absolute;
	}

	&.m-layout-pagination_and_controls &-title_container {
		display: none;
	}

	// Controls in inverse color
	&.m-inverse &-control {
		--carousel-control-color: #{$color-carousel_control__inverse};
	}

}
.b-carousel.m-inited[data-enable-autoplay="true"]{
	.b-carousel-control{
		@include media(md-down) {
			@include g-carousel(control, initialized);
			display: inline-flex;
			position: relative;
			&.m-previous{
				margin-left: 10px;
				left:0;
			}
			&.m-next{
				right: 0;
			}
		}

		}
}