.b-logo {
	display: block;
	transition: width 0.5s;
	width: var(--logo-width, #{$configuration-logo-width});

	&-wrapper {
		display: block;
		padding-bottom: calc(var(--logo-image-height, #{$configuration-logo-image-height}) / var(--logo-image-width, #{$configuration-logo-image-width}) * 100%);
		position: relative;
		transition: padding 0.5s linear;
	}

	&-image {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
