/* stylelint-disable
	property-no-vendor-prefix,
	property-no-unknown
*/

@mixin horizontal-scroll() {
	@include scrollbar(hidden);

	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	-ms-overflow-style: none; /* IE */
	overflow-x: auto;
	overscroll-behavior-x: contain;
	scroll-behavior: smooth;
	-ms-scroll-chaining: none;
	scroll-snap-type: x proximity;
	scrollbar-width: none; /* FF */
}
