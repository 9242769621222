/*md
# Carousel component
This carousel component is based on a simple built-in scroll functionality.

All layout, sizing, positioning is also controlled in CSS or with the help of HTML attributes.

## Carousel layout options - Number of slides per view

There are two types of configuration:
- static number of slides
- static slide's width

### Static number of slides

To define number of slides per view you can use a CSS custom properties or HTML attributes

#### CSS custom properties
`--carousel-slides_number` and `--carousel-number_of_gaps_per_view`.

We need `--carousel-number_of_gaps_per_view` for accurate calculations of slide's width.

Formulas for these calculations are defined in `g-carousel` mixin.

Default values for these properies are:

```
--carousel-slides_number: 1;
--carousel-number_of_gaps_per_view: 0;
```

```scss_example
.b-custom_carousel {
	@include g-carousel;

	--carousel-slides_number: 3;
	--carousel-number_of_gaps_per_view: 2;
}
```

#### HTML attributes

There's an HTML attribute: `data-slides-number` where you
can define viewport names which have static slides number configuration.

Here's the list of viewport names: sm, md, lg, xl.

To configure exact number of slides for each viewport - use CSS
custom properties in the `style` attribute:

- `--carousel-number_of_gaps_per_view-sm` and `--carousel-slides_number-sm` for Small
- `--carousel-number_of_gaps_per_view-md` and `--carousel-slides_number-md` for Medium
- `--carousel-number_of_gaps_per_view-lg` and `--carousel-slides_number-lg` for Large
- `--carousel-number_of_gaps_per_view-xl` and `--carousel-slides_number-xl` for XLarge

```example
<section class="b-carousel"
	data-slides-number="sm lg"
	style="
		--carousel-number_of_gaps_per_view-sm: 1; --carousel-slides_number-sm: 2;
		--carousel-number_of_gaps_per_view-lg: 3; --carousel-slides_number-lg: 3.5;
	"
>...</section>
```

### Dynamic number of slides

The opposite to the static number of slides is a dynamic number of slides.

You should use it when you don't need the exact number of slides per view
but rather slides width. Visually it will behave this way: when you stretch
viewport size number of slides will increase but the slide's width won't change.

To define slides width you can use CSS custom property or HTML attributes

#### CSS custom properties

There's just one CSS custom property you need: `--carousel-slides-width`

```scss_example
.b-custom_slider {
	--carousel-slides-width: 300px;

	@include media(sm) {
		--carousel-slides-width: 150px;
	}
}
```

#### HTML attributes

There's an HTML attribute: `data-slides-width` where you
can define viewport names which have static slides width configuration.

Here's the list of viewport names: sm, md, lg, xl.

To configure a slides width for each viewport - use CSS
custom properties in the `style` attribute:

- `--carousel-slides_width-sm` for Small
- `--carousel-slides_width-md` for Medium
- `--carousel-slides_width-lg` for Large
- `--carousel-slides_width-xl` for XLarge

```example
<section class="b-carousel"
	data-slides-width="md xl"
	style="
		--carousel-slides_width-md: 250px;
		--carousel-slides_width-xl: 350px;
	"
>...</section>
```

## Carousel layout options - Gutter value between slides

Gap between slides is controlled via CSS custom
property: `--carousel-gutter`

It has the default value: `--carousel-gutter: 0`

## Carousel layout options - Number of rows

Number of rows is controlled via CSS custom
property: `--carousel-rows_number`

It has the default value: `--carousel-rows_number: 1`

*/

@mixin g-carousel($el: root, $mod: default) {
	@if $el == root {
		@if $mod == default {
			// Default variables for sliders
			$_slides_number-default: 1;
			$_slides_width-default: 100%;
			$_slides_number_of_gaps_per_view-default: 0;
			// Core functionality
			/* stylelint-disable-next-line  length-zero-no-unit */
			--carousel-slides-width-calculated: calc((100% - var(--carousel-gutter, 0px) * var(--carousel-number_of_gaps_per_view, #{$_slides_number_of_gaps_per_view-default})) / var(--carousel-slides_number, 1));
			// Carousel slide width
			--carousel-slides-width: var(--carousel-slides-width-calculated);

			position: relative;

			@include media(sm) {
				&[data-slides-number~='sm'] {
					--carousel-number_of_gaps_per_view: var(--carousel-number_of_gaps_per_view-sm, #{$_slides_number_of_gaps_per_view-default});
					--carousel-slides_number: var(--carousel-slides_number-sm, #{$_slides_number-default});
				}

				&[data-slides-width~='sm'] {
					--carousel-slides-width: var(--carousel-slides_width-sm, #{$_slides_width-default});
				}
			}

			@include media(md) {
				&[data-slides-number~='md'] {
					--carousel-number_of_gaps_per_view: var(--carousel-number_of_gaps_per_view-md, #{$_slides_number_of_gaps_per_view-default});
					--carousel-slides_number: var(--carousel-slides_number-md, #{$_slides_number-default});
				}

				&[data-slides-width~='md'] {
					--carousel-slides-width: var(--carousel-slides_width-md, #{$_slides_width-default});
				}
			}

			@include media(lg) {
				&[data-slides-number~='lg'] {
					--carousel-number_of_gaps_per_view: var(--carousel-number_of_gaps_per_view-lg, #{$_slides_number_of_gaps_per_view-default});
					--carousel-slides_number: var(--carousel-slides_number-lg, #{$_slides_number-default});
				}

				&[data-slides-width~='lg'] {
					--carousel-slides-width: var(--carousel-slides_width-lg, #{$_slides_width-default});
				}
			}

			@include media(xl) {
				&[data-slides-number~='xl'] {
					--carousel-number_of_gaps_per_view: var(--carousel-number_of_gaps_per_view-xl, #{$_slides_number_of_gaps_per_view-default});
					--carousel-slides_number: var(--carousel-slides_number-xl, #{$_slides_number-default});
				}

				&[data-slides-width~='xl'] {
					--carousel-slides-width: var(--carousel-slides_width-xl, #{$_slides_width-default});
				}
			}
		}
	}

	@if $el == track {
		@if $mod == default {
			@include horizontal-scroll;

			display: grid;
			gap: var(--carousel-gutter, 0);
			grid-auto-columns: var(--carousel-slides-width, max-content);
			grid-auto-flow: column;
			grid-template-rows: repeat(var(--carousel-rows_number, 1), auto);
			position: relative; // it's needed for JS correct behavior
		}

		@if $mod == mandatory {
			scroll-snap-destination: 0% 100%;
			scroll-snap-points-x: repeat(100%);
			scroll-snap-type: x mandatory;
		}
	}

	@if $el == controls_container {
		@if $mod == default {
			align-self: end;
			grid-area: controls;
			margin-bottom: rh(6);
		}
	}

	@if $el == control {
		@if $mod == default {
			appearance: none;
			cursor: pointer;
			display: none;
			user-select: none;
			z-index: z(components, carousel, controls);
		}

		@if $mod == previous {
		}

		@if $mod == next {
		}

		@if $mod == disabled {
			pointer-events: none;
		}

		@if $mod == initialized {
			display: initial;
		}
	}

	@if $el == 	pagination_container {
		@if $mod == default {
			z-index: z(components, carousel, controls);
		}
	}

	@if $el == pagination_control {
		@if $mod == default {
			appearance: none;
			cursor: pointer;
			display: inline-block;
		}

		@if $mod == active {
			pointer-events: none;
		}
	}

	@if $el == slide {
		@if $mod == default {
			display: grid;
		}

		@if $mod == no-snap {
			scroll-snap-align: none;
		}

		@if $mod == snap-to-start {
			scroll-snap-align: start;
		}

		@if $mod == snap-to-center {
			scroll-snap-align: center;
		}
	}
}
