.b-minicart_panel {
	@include g-panel($el: overlay);

	position: relative;
	z-index: z(minicart);

	&.m-opened {
		@include g-panel($el: overlay, $mod: opened);
	}

	&-container {
		@include g-panel($el: panel);
		@include g-panel($el: panel, $mod: right);

		background: $color-minicart_panel-background;
	}

	&.m-opened &-container {
		@include g-panel($el: panel, $mod: opened);
		@include g-panel($el: panel, $mod: right_opened);
	}
}
